import React from "react";
import PT from "prop-types";
import { SubHeadline, Headline } from "../../atoms";
import { getCollectionName } from "../../helper/utils";
import "./styles.m.css";

export const SubscriptionBanner = ({ collection }) => {
  const { metadata } = collection;

  return (
    <div className="container">
      <div styleName="wrapper">
        <div styleName="magazine-wrapper">
          <img
            styleName="image-wrapper"
            src="https://images.assettype.com/fortuneindia/2025-02-11/kettazc6/twitter-mnc-500-cover.jpg"
            alt="subscription-cover-image"
          />
          <div styleName="circled-date">{metadata.title}</div>
        </div>
        <div styleName="text-wrapper">
          <Headline value={getCollectionName(collection)} fontSize="halfjumbo" />
          <SubHeadline value={collection.summary} />
          <div styleName="button-wrapper">
            <a styleName="button-item" href={metadata["grab-a-copy"]} target="_blank" rel="noopener noreferrer">
              Read Now
            </a>
            {/* <a
              styleName="button-item-outline"
              href={metadata["look-inside-link"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Look inside
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionBanner.propTypes = {
  collection: PT.object
};
